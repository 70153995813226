<template>
  <div class="nav">
    <div class="nav-header">
      <div class="nav-left">
        <img class="logo" src="../assets/logo.png" alt="" />
        <div class="navBtn" @click="backHome">
          <img class="home" src="../assets/back.png" alt="" />
          <div>返回</div>
        </div>
      </div>
      <div class="nav-title">公济健康频道</div>
      <div class="nav-date">
        <div>{{ weekDay }}</div>
        <div>{{ currentTime }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "navigation",
  computed: {
    weekDay() {
      const days = ["日", "一", "二", "三", "四", "五", "六"];
      return "星期" + days[new Date().getDay()];
    },
  },
  data() {
    return {
      currentTime: "",
      isHome: true,
    };
  },
  watch: {
    // 监听路由对象$route的变化
    $route: {
      handler: function (to, from) {
        // 路由发生变化时执行的代码
        console.log("Route changed from", from.path, "to", to.path);
        if (to.path.indexOf("/home") > -1) {
          this.isHome = true;
        } else {
          this.isHome = false;
        }
      },
      // 设置为深度监听
      deep: true,
    },
  },
  created() {
    this.updateTime(); // 更新一次时间
    setInterval(this.updateTime, 1000); // 每秒更新一次时间

    let path = window.location.pathname;
    if (path.indexOf("detail") > -1) {
      this.isHome = false;
    }
  },
  methods: {
    updateTime() {
      const now = new Date();
      let date = `${now.getFullYear()}年${(now.getMonth() + 1)
        .toString()
        .padStart(2, "0")}月${now.getDate().toString().padStart(2, "0")}日`;

      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      this.currentTime = date + " " + hours + ":" + minutes + ":" + seconds; // 使用toLocaleTimeString获取当前时间
    },
    backHome() {
      let path = window.location.pathname;
      if (path.indexOf("detail") > -1) {
        var url = window.location.origin + "/home";
        window.location.href = url;
      } else {
        if (window.WebJsObject) {
          window.WebJsObject.finish();
        } else {
          console.log(
            "WebJsObject is undefined, the app may not be launched or the device may not support JavaScript interfaces."
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  height: 100px;
  .nav-header {
    width: 100%;
    height: 78px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    margin-bottom: 12px;

    .nav-left {
      // width: 261px;
      display: flex;
      align-items: center;
    }

    .logo {
      height: 48px;
      width: auto;
    }
    .navBtn {
      display: flex;
      align-items: center;
      font-size: 22px;
      color: white;
    }
    .home {
      margin-left: 24px;
      margin-right: 12px;
      height: 44px;
      width: auto;
      cursor: pointer;
    }
    .nav-title {
      font-size: 36px;
      font-weight: 500;
    }
    .nav-date {
      width: 261px;
      font-size: 22px;
      line-height: 30.8px;
      text-align: right;
    }
  }
}
</style>
