import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/appMain.vue";
// import ShareLayout from "../layout/share.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home/index"),
        name: "home",
        meta: { title: "首页", role: "home", position: "left" },
      },
      {
        path: "detail",
        component: () => import("@/views/detail/index"),
        name: "detail",
        meta: { title: "基础信息", role: "detail", position: "left" },
      },
    ],
  },
  {
    path: "/share",
    component: () => import("@/views/share/index"),
    // component: ShareLayout,
    hidden: true,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
