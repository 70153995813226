<template>
  <div class="page-container">
    <div v-if="visible" class="content">
      <Nav></Nav>
      <router-view />
    </div>
  </div>
</template>
<script>
import Nav from "./nav.vue";

export default {
  name: "AppMain",
  components: { Nav },
  mounted() {
    window.onresize = () => {
      // 执行重新刷新布局的操作
      this.$router.go(); //  重新页面加载
    };
  },
  data() {
    return {
      visible: true,
    };
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  position: relative;
  width: 100vw;
  height: 800px;
  background: linear-gradient(180deg, #0955bc 0%, #b0c8eb 100%);
}
.content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 32px 16px 32px;
  box-sizing: border-box;

  background-image: url("../assets/bj.png"); /* 背景图片路径 */
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 背景图片不重复 */
}
</style>
